import { React, useEffect } from 'react'
import { Helmet } from 'react-helmet'
// import { HashLink as Link } from 'react-router-hash-link';

import MainHeader from '../../../components/MainHeader/MainHeader.js'
import MainFooter from '../../../components/MainFooter/MainFooter.js'
// import CTAButton from '../../../components/CTAButton/CTAButton.js'

// import { lP, oP, sT } from '../../../main.js'

import '../../../main.min.css'
import './LP_Channext_Apple.min.css'
import './MLP_Channext_Apple.min.css'


// const handleIframeLoad = (iframe) => {
//     console.log(iframe.contentDocument);

//     if (iframe && iframe.contentDocument) {
//         // Access the element within the iframe by ID
//         const targetElement = iframe.contentDocument.querySelector('.navbar-brand');
//         console.log(targetElement);

//         if (targetElement) {
//             // Modify the style of the element
//             // targetElement.style.color = '#ff0000'; // Set text color to red
//             targetElement.remove()
//         }
//     }
// };

const ChannextWidget = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://content.channext.com/js/widget.js";
        script.type = "text/javascript";
        script.async = false; //true wenn Seite vorher schon fertig laden darf

        document.body.appendChild(script);

        // setTimeout(() => {
        //     // document.querySelector("iframe").contentDocument.querySelector('.navbar-brand').remove();

        //     const iframe = document.querySelector("iframe");

        //     console.log(iframe);

        //     if (iframe) {
        //         // Handle the initial load of the iframe content
        //         iframe.onload = () => handleIframeLoad(iframe);
        //     }
        // }, 8000)


        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id="channext-widget" data-source="https://content.channext.com/resellers/6121/widget/9173"></div>;
};


export default function LP_Channext_Apple() {


    return (
        <div className='mainCon' id='mainCon'>
            <MainHeader selectedsite={"lp_channext_apple"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Umstieg auf Apple</title>
                <meta property="og:title" content="Umstieg auf Apple" />
                <meta property="og:description" content="Get your Mac now. Jetzt umsteigen auf Apple for Business" />
            </Helmet>
            <section className='sec_start' id='sec_start--lp_apple_widget'>
                {/* <div id="channext-widget" data-source="https://content.channext.com/resellers/6121/widget/9173"></div><script type="text/javascript" src="https://content.channext.com/js/widget.js"></script> */}
                <ChannextWidget />
            </section>

            <br />

            <MainFooter />
        </div>
    )
}