
import { React, useState } from 'react'

// import logo_breit from '../../assets/pictures/logos/it-liepert-logo-obg.png'
import logo_breit from '../../assets/pictures/logos/it-liepert-logo_textunten_obgs.png'
import ton_logo from '../../assets/pictures/logos/ton_logo-breit-white_cut-small.webp'

import { lP, oP, sT } from '../../main.js'


import './MainFooter.min.css'
import './MMainFooter.min.css'
import '../../main.min.css'


export default function MainFooter(props) {


    return (
        <div className='mainfooterdiv'>

            <div className='mainfooter'>

                <img src={logo_breit} alt="IT-Liepert Logo" /><br /><br />
                <div className='footerlinks'>
                    <a href='/impressum'>Impressum</a>
                    <a href='/datenschutz'>&nbsp;&nbsp;Datenschutz</a>
                </div>
                <p>©2025 it.liepert.gmbh <br className='onlymobile' />(made by <a href="https://tonetwork.de" style={{ textDecoration: "underline" }} target="_blank">ToNetwork UG</a>)</p>
            </div >
        </div >
    )
}